import React from "react";

function WeatherScreen() {
    return (
        <div className="row h-100 w-100 d-flex align-items-center p-3">
            <div className="col d-flex justify-content-end">
                <iframe
                    title="Regen"
                    frameBorder="0"
                    scrolling="no"
                    className="weerplazaWidget"
                    src="https://www.weerplaza.nl/weerwidgets/regenradar/?SXNGdWxsV2lkdGg9ZmFsc2UmSXNIRD10cnVlJlpvb21MZXZlbD03LjAmTWF4V2lkdGg9ODAw"
                    style={{
                        width: "100%",
                        maxWidth: "800px",
                        height: "698px",
                        borderRadius: "5px"
                    }}
                />
            </div>
            <div className="col d-flex justify-content-center">
                <iframe
                    title="Weer"
                    frameBorder="0"
                    scrolling="no"
                    className="weerplazaWidget"
                    src="https://www.weerplaza.nl/weerwidgets/verwachting/2742711/"
                    style={{
                        width: "60%",
                        maxWidth: "800px",
                        height: "230px",
                        transform: "scale(1.5)",
                        borderRadius: "3px"
                    }}
                />
            </div>
        </div>
    )
}

export default React.memo(WeatherScreen);
