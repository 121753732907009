import React from "react";
import axios from "axios";

import WeatherLayout from "../components/weather/WeatherLayout";
import WeatherMap from "../components/weather/WeatherMap";

class Weather2Screen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            weatherData: null,
            error: null,
            showing: false,
            showMap: true,
        }
        this.timerUpdate = this.timerUpdate.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.inCarousel) {
            if(!prevProps.showing && this.props.showing) {
                this.setState({ showing: true });
            }
        }
    }

    componentDidMount() {
        this.startTimer();
        if(!this.props.inCarousel) {
            this.setState({ showing: true });
        }
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        if(this.refreshInterval) {
            this.stopTimer();
        }
        this.refreshInterval = setInterval(this.timerUpdate, 5 * 60 * 1000);
        this.timerUpdate();
    }

    stopTimer() {
        if(!this.refreshInterval) {
            return;
        }
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    }

    timerUpdate() {
        this.getWeather();
        if(!this.props.inCarousel || this.props.showing) {
            this.setState({ showMap: false }, () => {
                this.setState({ showMap: true });
            });
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getWeather() {
        axios.get("/getWeather", { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        weatherData: response.data.data
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de weerdata! (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout bij het ophalen van de weerdata!" });
            });
    }

    render() {
        const {
            weatherData,
            error,
            showing,
            showMap
        } = this.state;
        return (
            <div className="h-100 w-100" style={{ position: "relative", overflow: "hidden" }}>
                <div style={{ position: "absolute", zIndex: 500, top: 0, left: 0 }} className="h-100 w-100">
                    <WeatherLayout
                        current={ weatherData ? weatherData.current : null }
                        forecast={ weatherData ? weatherData.forecast : null }
                        error={ error }
                    />
                </div>
                <div className="h-100 w-100" style={{ zIndex: 0, backgroundColor: "black" }}>
                    { showing && showMap && (
                        <WeatherMap/>
                    )}
                </div>
            </div>
        );
    }
}

export default Weather2Screen;
