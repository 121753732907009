import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Annotation,
    ColorScheme,
    MapType
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "../components/apple-maps/AppleMapsMap";
import Loading from "../components/Loading";
import getCenterCoordinate from "../utilities/getCenterCoordinate";
import getAnnotationGlyphImage from "../utilities/getAnnotationGlyphImage";
import getAnnotationGlyphColor from "../utilities/getAnnotationGlyphColor";

class CarTrackerScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            trackers: null,
            error: null,
            center: getCenterCoordinate([])
        }
        this.getTrackers = this.getTrackers.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.props.showing && prevProps.showing) {
            this.stopTimer();
        }
        if(this.props.showing && !prevProps.showing) {
            this.startTimer();
        }
    }

    componentDidMount() {
        this.startTimer();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer() {
        if(this.refreshInterval) {
            this.stopTimer();
        }
        this.refreshInterval = setInterval(this.getTrackers, 5 * 1000);
        this.getTrackers();
    }

    stopTimer() {
        if(!this.refreshInterval) {
            return;
        }
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getTrackers() {
        if(this.loading) {
            return;
        }
        this.loading = true;
        this.setState({ error: null });
        axios.get("/getCarTrackers", { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    const trackers = response.data.trackers.filter((tracker) => {
                        return tracker.latitude && tracker.longitude;
                    });
                    this.setState({
                        trackers,
                        center: getCenterCoordinate(trackers)
                    });
                } else {
                    this.setState({ trackers: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ trackers: null, error: this.getErrorMessage(null) });
            })
            .finally(() => {
                this.loading = false;
            })
    }

    render() {
        const trackers = this.state.trackers;
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!trackers) {
            return (
                <Loading white/>
            )
        }
        const {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        } = this.state.center;
        return (
            <React.Fragment>
                { trackers.length === 0 && (
                    <div className="text-white d-flex justify-content-center align-items-center" style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1
                    }}>
                        <h1>Geen trackers met locatie.</h1>
                    </div>
                )}
                <AppleMapsMap
                    autoAdjust
                    latitude={ centerLatitude ? centerLatitude : 51.994459 }
                    longitude={ centerLongitude ? centerLongitude : 4.180937 }
                    spanLat={ latitudeSpan ? latitudeSpan : 0.07 }
                    spanLong={ longitudeSpan ? longitudeSpan : 0.05 }
                    height="100%"
                    width="100%"
                    initialMapType={ MapType.Standard }
                    colorScheme={ ColorScheme.Dark }
                >
                    { trackers.map((tracker) => {
                        const glyphImage = getAnnotationGlyphImage(tracker);
                        const glyphColor = getAnnotationGlyphColor(tracker);
                        return (
                            <Annotation
                                key={ tracker.id }
                                id={ tracker.id }
                                latitude={ tracker.latitude }
                                longitude={ tracker.longitude }
                                color={ tracker.color }
                                title={ tracker.name }
                                glyphImage={ glyphImage }
                                glyphColor={ glyphColor }
                            />
                        )
                    })}
                </AppleMapsMap>
            </React.Fragment>
        )
    }
}

export default CarTrackerScreen;
