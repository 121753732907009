import React from "react";
import { createRoot } from "react-dom/client";

import * as Sentry from "@sentry/react";

import moment from "moment";
import "moment/locale/nl";
import axios from "axios";
import { stringify } from "qs";

import App from "./App";

import "./scss/intranet.scss";

// Start Sentry if not development environment.
const development = process.env.REACT_APP_ENVIRONMENT === "development";
if(development) {
    console.log("Running in development mode. Sentry error reporting is disabled.");
} else {
    Sentry.init({
        dsn: "https://66815d2d1d4148dea0447e9c90ca48ed@sentry1.zsnode.com/26",
        beforeSend(event, hint) {
            if (event.exception) {
                Sentry.showReportDialog({
                    eventId: event.event_id,
                    title: "Er is een fout opgetreden.",
                    subtitle: "De foutmelding is verstuurd.",
                    subtitle2: "Vul hieronder in wat je deed vlak voor deze melding is verschenen.",
                    labelName: "Naam",
                    labelEmail: "Email",
                    labelComments: "Wat gebeurde er?",
                    labelClose: "Sluiten",
                    labelSubmit: "Versturen",
                    errorGeneric: "Er ging iets fout bij het versturen van je feedback. Probeer het opnieuw.",
                    errorFormEntry: "Sommige velden zijn ongeldig. Controleer de velden en probeer het opnieuw.",
                    successMessage: "Je feedback is verstuurd. Bedankt!"
                });
            }
            return event;
        },
        autoSessionTracking: true,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],
        // We recommend adjusting this value in production, or using tracesSampler for finer control
        tracesSampleRate: 1.0,
    });
}

moment.locale('nl');

if(development) {
    axios.defaults.baseURL = "/v2/";
} else {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL ? "https://" + process.env.REACT_APP_API_URL + "/v2/" : "https://dev.api.intranet.smithsolar.nl/v2/";
}
axios.defaults.withCredentials = true;

axios.defaults.transformRequest = [function (data) {
    data = stringify(data);
    return data;
}];

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <App />
);
