import React, {
    useMemo
} from "react";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";

import Loading from "../Loading";
import HourlyWeatherCard from "./HourlyWeatherCard";
import useWindowSize from "../../hooks/WindowsSize";
import CurrentWeatherCard from "./CurrentWeatherCard";

function WeatherLayout({ current, forecast, error }) {
    const windowSize = useWindowSize();
    const { minEntry, maxEntries } = useMemo(() => {
        return {
            minEntry: 1,
            maxEntries: Math.floor(windowSize.width / (1920 / 12))
        };
    }, [windowSize]);
    const forecastList = useMemo(() => {
        if(!forecast) {
            return null;
        }
        return forecast.list.filter((entry, index) => {
            return !(index >= maxEntries + minEntry || index < minEntry);
        }).map((entry, index, array) => {
            let type;
            if(index === 0) {
                type = "start";
            } else if (index === array.length - 1) {
                const prevDate = moment(array[index - 1]["dt_txt"]);
                const curDate = moment(entry["dt_txt"]);
                if(prevDate.format("YYYY-MM-DD") !== curDate.format("YYYY-MM-DD")) {
                    type = "single";
                } else {
                    type = "end";
                }
            } else {
                const prevDate = moment(array[index - 1]["dt_txt"]);
                const curDate = moment(entry["dt_txt"]);
                const nextDate = moment(array[index + 1]["dt_txt"]);
                if(prevDate.format("YYYY-MM-DD") !== curDate.format("YYYY-MM-DD")) {
                    type = "start-spacer";
                } else if(nextDate.format("YYYY-MM-DD") !== curDate.format("YYYY-MM-DD")) {
                    type = "end-spacer";
                } else {
                    type = "middle";
                }
            }
            return { ...entry, type };
        });
    }, [forecast, minEntry, maxEntries]);

    if(error) {
        return (
            <div className="d-flex justify-content-center pt-5">
                <Alert variant="danger">{ error }</Alert>
            </div>
        )
    }
    if(!current || !forecast) {
        return (
            <div className="container-fluid pt-5">
                <Loading white/>
            </div>
        )
    }

    return (
        <div className="container-fluid p-5 h-100">
            <div className="d-flex flex-column h-100">
                <div className="flex-grow-1 d-flex flex-row align-items-start">
                    <CurrentWeatherCard
                        current={ current }
                    />
                </div>
                <div className="row px-3 pt-4">
                    { forecastList.map((weatherEntry, index) => (
                        <HourlyWeatherCard
                            weatherEntry={ weatherEntry }
                            type={ weatherEntry.type }
                            key={ index }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default React.memo(WeatherLayout);
