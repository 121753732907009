import React, {
    useMemo
} from "react";
import moment from "moment";

import TagPill from "../TagPill";
import ReactMarkdownBreaks from "../ReactMarkdownBreaks";
import PlanningItemParentStatusDot from "./PlanningItemParentStatusDot";

function PlanningItemContent({ planningItem, images }) {
    const address = useMemo(() => {
        if(!planningItem.street) {
            return null;
        }
        return `${planningItem.street} ${planningItem.houseNumber}, ${planningItem.city}`;
    }, [planningItem]);

    const isToday = useMemo(() => {
        return planningItem.date === moment().format("YYYY-MM-DD");
    }, [planningItem]);

    let description = planningItem.description;
    if(planningItem.descriptionSuffix) {
        description += "\n\n" + planningItem.descriptionSuffix;
    }

    const planningItemImages = !images ? [] : images.filter((image) => {
        if(planningItem.lead) {
            if(image.parent && image.parentType === "leadComment") {
                return image.parent.leadId === planningItem.lead.id;
            }
            if(image.parent && image.parentType === "lead") {
                return image.parent.id === planningItem.lead.id;
            }
        }
        if(planningItem.outage) {
            if(image.parent && image.parentType === "outageComment") {
                return image.parent.outageId === planningItem.outage.id;
            }
            if(image.parent && image.parentType === "outage") {
                return image.parent.id === planningItem.outage.id;
            }
        }
        if(planningItem.installation) {
            if(image.parent && image.parentType === "installationComment") {
                return image.parent.installationId === planningItem.installation.id;
            }
            if(image.parent && image.parentType === "installation") {
                return image.parent.id === planningItem.installation.id;
            }
        }
        return false;
    });

    return (
        <div className="planning-item" key={ planningItem.id }>
            <div className="d-flex mb-2 align-items-center">
                <div className="flex-grow-1">
                    <TagPill color={ planningItem.type.color }>{ planningItem.type.name }</TagPill>
                    <b> { planningItem.name }</b>
                    <br/>
                    <span className="text-muted">{ address }</span>
                </div>
                <div className="px-2">
                    <PlanningItemParentStatusDot planningItem={ planningItem }/>
                </div>
            </div>
            { images && (
                <p className="mb-2">
                    { planningItemImages.length === 0 && isToday && planningItem && moment().hour() < 12 ? (
                        <b className="text-warning">
                            <i className="fas fa-images"/> Nog geen foto's gemaakt
                        </b>
                    ) : planningItemImages.length === 0 ? (
                        <b className="text-danger">
                            <i className="fas fa-images"/> Geen foto's gemaakt!
                        </b>
                    ) : planningItemImages.length === 1 ? (
                        <b className="text-warning">
                            <i className="fas fa-images"/> Maar 1 foto
                        </b>
                    ) : planningItemImages.length < 5 ? (
                        <b className="text-warning">
                            <i className="fas fa-images"/> Maar { planningItemImages.length } foto's
                        </b>
                    ) : (
                        <b className="text-success">
                            <i className="fas fa-images"/> { planningItemImages.length } foto's
                        </b>
                    )}
                </p>
            )}
            <div style={{ fontSize: "0.9rem" }}>
                <ReactMarkdownBreaks message={ description }/>
            </div>
        </div>
    )
}

export default React.memo(PlanningItemContent);
