import React, {
    useMemo
} from "react";

export function OpenWeatherIconTest() {
    const allIcons = useMemo(() => {
        return [
            "01",
            "02",
            "03",
            "04",
            "09",
            "10",
            "11",
            "13",
            "50",
        ]
    }, []);
    return (
        <div className="card card-glass card-glass-darkblue">
            <div className="card-body">
                <div className="d-flex flex-row">
                    { allIcons.map((icon) => (
                        <CombinedIconCode
                            icon={ icon }
                            key={ icon }
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

function CombinedIconCode({ icon }) {
    return (
        <div className="d-flex flex-column align-items-center mx-3">
            <p className="fa-3x" style={{ border: "1px solid white" }}>
                <OpenWeatherIcon icon={ icon + "d" }/>
            </p>
            <p className="fa-3x" style={{ border: "1px solid white" }}>
                <OpenWeatherIcon icon={ icon + "n" }/>
            </p>
            <p className="m-0">
                { icon }
            </p>
        </div>
    )
}

function OpenWeatherIcon({ icon }) {
    switch(icon) {
        case "01d":
            return (
                <i className="fa-solid fa-sun fa-fw sun-color"/>
            );
        case "01n":
            return (
                <i className="fa-solid fa-moon fa-fw"/>
            );
        case "02d":
            return (
                <i className="fa-duotone fa-sun-cloud fa-fw fad-sun-secondary"/>
            );
        case "02n":
            return (
                <i className="fa-duotone fa-moon-cloud fa-fw"/>
            );
        case "03d":
        case "03n":
            return (
                <i className="fa-solid fa-cloud fa-fw"/>
            );
        case "04d":
            return (
                <i className="fa-duotone fa-clouds-sun fa-fw fad-sun-secondary"/>
            );
        case "04n":
            return (
                <i className="fa-duotone fa-clouds-moon fa-fw"/>
            );
        case "09d":
        case "09n":
            return (
                <i className="fa-duotone fa-cloud-showers-heavy fa-fw fad-rain-secondary"/>
            );
        case "10d":
            return (
                <i className="fa-duotone fa-cloud-sun-rain fa-fw"/>
            );
        case "10n":
            return (
                <i className="fa-duotone fa-cloud-moon-rain fa-fw"/>
            );
        case "11d":
        case "11n":
            return (
                <i className="fa-duotone fa-thunderstorm fa-fw fad-thunder-secondary"/>
            );
        case "13d":
        case "13n":
            return (
                <i className="fa-solid fa-snowflake fa-fw"/>
            );
        case "50d":
        case "50n":
            return (
                <i className="fa-duotone fa-fog fa-fw"/>
            );
        default: return null;
    }
}

export default React.memo(OpenWeatherIcon);
