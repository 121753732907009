import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import {
    AppleMapsManager
} from "./components/apple-maps/AppleMapsManager";
import ChristmasSnow from "./components/ChristmasSnow";

import ImagesScreen from "./screens/ImagesScreen";
import ImagesScrollingScreen from "./screens/ImagesScrollingScreen";
import ImageLeaderboardScreen from "./screens/ImageLeaderboardScreen";
import PlanningScreen from "./screens/PlanningScreen";
import RotatingScreen from "./screens/RotatingScreen";
import WeatherScreen from "./screens/WeatherScreen";
import Weather2Screen from "./screens/Weather2Screen";
import MapScreen from "./screens/MapScreen";
import CarTrackerScreen from "./screens/CarTrackerScreen";
import MerryChristmasScreen from "./screens/MerryChristmasScreen";
import AprilFoolsScreen from "./screens/AprilFoolsScreen";
import WKPouleScreen from "./screens/WKPouleScreen";
import AutoDisplaySwitching from "./displays/AutoDisplaySwitching";
import GridSenseScreen from "./screens/gridsense/GridSenseScreen";

import ModalController from "./components/interactive-modals/ModalController";

import regularBackground from "./img/solar-panels.jpg";
import wattcoBackground from "./img/wattco.jpg";
import snowBackground from "./img/snowpanels.jpg";

function App() {
    const [background, setBackground] = useState(null);
    const [today, setToday] = useState(null);

    useEffect(() => {
        setToday(moment());
        const timer = setInterval(() => {
            console.log("Updated today");
            setToday(moment());
        }, 60 * 60 * 1000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if(!today) {
            return;
        }
        const year = today.year();
        if(today.format("MM-DD") === "04-01") {
            setBackground(wattcoBackground);
        } if(today > moment(`${year}-12-20`) && today < moment(`${year}-12-27`)) {
            setBackground(snowBackground);
        } else {
            setBackground(regularBackground);
        }
    }, [today]);

    return (
        <AppleMapsManager monitoringToken>
            <ChristmasSnow/>
            <div className="background vh-100" style={{ backgroundImage: "url(" + background + ")" }}>
                <div className="background-overlay">
                    <ModalController/>
                    <Router>
                        <Switch>
                            <Route path="/images">
                                <ImagesScreen/>
                            </Route>
                            <Route path="/images-scrolling">
                                <ImagesScrollingScreen/>
                            </Route>
                            <Route path="/leaderboard">
                                <ImageLeaderboardScreen/>
                            </Route>
                            <Route path="/planning">
                                <PlanningScreen/>
                            </Route>
                            <Route path="/weather">
                                <WeatherScreen/>
                            </Route>
                            <Route path="/weather2">
                                <Weather2Screen/>
                            </Route>
                            <Route path="/map">
                                <MapScreen/>
                            </Route>
                            <Route path="/cartracker">
                                <CarTrackerScreen/>
                            </Route>
                            <Route path="/rotating">
                                <RotatingScreen/>
                            </Route>
                            <Route path="/merry-christmas">
                                <MerryChristmasScreen/>
                            </Route>
                            <Route path="/april-fools">
                                <AprilFoolsScreen/>
                            </Route>
                            <Route path="/wk">
                                <WKPouleScreen/>
                            </Route>
                            <Route path="/auto">
                                <AutoDisplaySwitching/>
                            </Route>
                            <Route path="/gridsense">
                                <GridSenseScreen/>
                            </Route>
                            <Route path="/">
                                <div className="w-100 text-center text-white">
                                    <h1>404 Not Found</h1>
                                </div>
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </div>
        </AppleMapsManager>
    )
}

export default App;
