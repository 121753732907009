import React from "react";
import {
    EnergyFlow
} from "@gridsense/react-energy-flow";

import LiveUpdateIndicator from "./LiveUpdateIndicator";
import Loading from "../../../components/Loading";

function DashboardTotalEnergyFlowCard({ className, data, animate }) {
    return (
        <div className={ "card mb-3" + (className ? ` ${className}` : "") }>
            <div className="card-body">
                <div className="d-flex flex-row mb-2 justify-content-center">
                    <div className="flex-grow-1">
                        <h5 className="card-title mb-0">Live inzicht van alle GridSense installaties</h5>
                    </div>
                    <div className="d-flex align-items-center pt-1">
                        <LiveUpdateIndicator animate={ animate }/>
                    </div>
                </div>
                { !data ? (
                    <Loading/>
                ) : (
                    <div className="d-flex justify-content-center align-items-center h-100">
                        <div>
                            <EnergyFlow
                                {...data}
                                scale={ 1.75 }
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default React.memo(DashboardTotalEnergyFlowCard);
