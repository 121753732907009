import React from "react";
import {
    MapContainer,
    TileLayer
} from "react-leaflet";

function WeatherMap() {
    return (
        <div className="mapcontainer">
            <MapContainer center={[50.994100, 4.179230]} zoom={7} scrollWheelZoom={true} className="h-100 w-100">
                <TileLayer
                    className="basemap"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                />
                <TileLayer
                    className="weather"
                    attribution='<a href="https://openweathermap.org/">OpenWeather</a>'
                    url="https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=57d0ec1d8f5cc5dfeb9cc6370cdc2aef"
                />
            </MapContainer>
        </div>
    )
}

export default React.memo(WeatherMap);
