import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";

function ClockColumn() {
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);

    useEffect(() => {
        const update = () => {
            const now = moment();
            setDate(now.format("LL"));
            setTime(now.format("HH:mm:ss"));
        }
        update();
        const interval = setInterval(update, 1000);
        return () => clearInterval(interval);
    }, []);

    if(!date || !time) {
        return null;
    }
    return (
        <div className="d-flex flex-grow-1">
            <div className="card w-100">
                <div className="card-body py-0 d-flex h-100 w-100 align-items-center justify-content-center">
                    <div className="text-center">
                        <h3 className="m-0 mt-1 monospace">
                            <b>{ time }</b>
                        </h3>
                        <p className="m-0 mb-1">
                            { date }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ClockColumn);
