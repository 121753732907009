import React from "react";
import moment from "moment";

import ResizableImage from "./ResizableImage";

function ImageGallary({ images }) {
    return (
        <div className="d-flex flex-column w-100 justify-content-center">
            <div className="row no-gutters row-cols-1 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
                { images.map( image =>
                    <div className="col image-box" key={ image.id }>
                        <ResizableImage
                            image={ image }
                            alt={ "Image " + image.id }
                            size="315x315"
                        />
                        <div className="image-box-overlay-always">
                            <div className="d-flex w-100 h-100 align-items-end">
                                <div>
                                    <span className="badge bg-dark text-white">
                                        { image.author.name }
                                    </span>
                                    <br/>
                                    <span className="badge bg-dark text-white">
                                        { moment(image.date).calendar() }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(ImageGallary);
