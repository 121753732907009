import React from "react";

function LiveUpdateIndicator({ className, animate }) {
    let classNames = ["livenow"];
    if(className) {
        classNames.push(className);
    }
    if(animate) {
        classNames.push("animate");
    }
    return (
        <div className={ classNames.join(" ") }>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default React.memo(LiveUpdateIndicator);
