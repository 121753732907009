import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment-timezone";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";
import {
    ErrorBoundary
} from "@sentry/react";

import ChartLoading from "../../components/charts/ChartLoading";
import ChartNoData from "../../components/charts/ChartNoData";

function GridSenseEnergyPriceChart({ chartData, currentDateMoment }) {
    const [data, setData] = useState(null);

    const currentHourString = useMemo(() => {
        return currentDateMoment.format("YYYY-MM-DD HH");
    }, [currentDateMoment]);

    useEffect(() => {
        if(!chartData || chartData.length === 0) {
            setData([]);
            return;
        }
        setData([{
            label: "Energieprijzen",
            data: chartData.map((priceDatum) => ({
                key: moment(priceDatum.date).format("YYYY-MM-DD HH:mm:ss"),
                value: priceDatum.price
            }))
        }]);
    }, [chartData]);
    const minimumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = 0;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue -= 0.02;
                }
                workingValue = Math.min(workingValue, comparedValue);
            });
        });
        return workingValue;
    }, [data]);
    const maximumValue = useMemo(() => {
        if(!data) {
            return 0;
        }
        let workingValue = 0.10;
        data.forEach((dataSet) => {
            dataSet.data.forEach((dataPoint) => {
                let comparedValue = dataPoint.value;
                if(dataPoint.value < 0) {
                    comparedValue += 0.02;
                }
                workingValue = Math.max(workingValue, comparedValue);
            });
        });
        return Math.ceil(workingValue * 100) / 100;
    }, [data]);

    const primaryAxis = useMemo(() => ({
        getValue: (datum) => moment(datum.key).format("HH:mm")
    }), []);
    const secondaryAxes = useMemo(() => [{
        getValue: (datum) => datum.value,
        min: minimumValue,
        max: maximumValue
    }], [minimumValue, maximumValue]);

    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <ErrorBoundary fallback={
            <Alert variant="danger">Er ging iets fout. Probeer het later opnieuw.</Alert>
        }>
            <Chart
                options={{
                    data,
                    primaryAxis,
                    secondaryAxes,
                    getDatumStyle: (datum) => {
                        if(moment(datum.originalDatum.key).format("YYYY-MM-DD HH") !== currentHourString) {
                            return {}
                        }
                        return {
                            color: "#40bfff"
                        }
                    }
                }}
            />
        </ErrorBoundary>
    )
}

export default React.memo(GridSenseEnergyPriceChart);
