import React, {
    useEffect,
    useRef,
    useState
} from "react";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Loading from "../../components/Loading";
import useWindowSize from "../../hooks/WindowsSize";

function EnergyHandlingModeItem({ selectedMode, totalAmount, timeFormat = "HH:mm", rotateLabels }) {
    return (
        <OverlayTrigger overlay={
            <Tooltip id={`tooltip-EnergyHandlingModeItem`}>
                { selectedMode.mode.name }
            </Tooltip>
        }>
            <div
                className="energy-handling-item-container"
                style={{
                    width: `${100 / totalAmount}%`
                }}
            >
                <div
                    className="energy-handling-item-block"
                    style={{
                        backgroundColor: selectedMode.mode.color
                    }}
                />
                <div
                    className={ "energy-handling-item-text" + (rotateLabels ? " rotate" : "") }
                >
                    { moment(selectedMode.date).format(timeFormat) }
                </div>
            </div>
        </OverlayTrigger>
    )
}

function GridSenseEnergyHandlingChart({ chartData, title }) {
    const cardRef = useRef();
    const windowSize = useWindowSize();

    const [modes, setModes] = useState(null);
    const [unavailable, setUnavailable] = useState(false);
    const [rotateLabels, setRotateLabels] = useState(false);

    useEffect(() => {
        setModes(chartData);
        setUnavailable(!chartData || chartData.length === 0);
    }, [chartData]);

    useEffect(() => {
        if(!cardRef.current) {
            setRotateLabels(false);
            return;
        }
        if(cardRef.current.offsetWidth >= 800) {
            setRotateLabels(false);
            return;
        }
        setRotateLabels(true);
    }, [cardRef, windowSize]);

    return (
        <div ref={ cardRef } className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">{ title }</h5>
                { unavailable ? (
                    <p className="m-0">
                        Geen sturing beschikbaar.
                    </p>
                ) : !modes ? (
                    <Loading/>
                ) : (
                    <div className="energy-handling-chart-bar">
                        { modes.map((selectedMode) => (
                            <EnergyHandlingModeItem
                                key={ selectedMode.date }
                                selectedMode={ selectedMode }
                                totalAmount={ modes.length }
                                timeFormat="HH:mm"
                                rotateLabels={ rotateLabels }
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default React.memo(GridSenseEnergyHandlingChart);
