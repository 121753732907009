import React, {
    useMemo
} from "react";
import {
    Modal
} from "react-bootstrap";

import MapScreen from "../../screens/MapScreen";
import CarTrackerScreen from "../../screens/CarTrackerScreen";
import PlanningScreen from "../../screens/PlanningScreen";
import GridSenseScreen from "../../screens/gridsense/GridSenseScreen";
import Weather2Screen from "../../screens/Weather2Screen";
import ImagesScrollingScreen from "../../screens/ImagesScrollingScreen";

import regularBackground from "../../img/solar-panels.jpg";

function ScreenModal({ show, handleClose, screenType, timeoutString }) {
    const screen = useMemo(() => {
        switch(screenType) {
            case "images": return (<ImagesScrollingScreen/>);
            case "mapPlanning": return (<MapScreen/>);
            case "mapCarTracker": return (<CarTrackerScreen/>);
            case "planning": return (<PlanningScreen/>);
            case "weather": return (<Weather2Screen/>);
            case "gridSense": return (<GridSenseScreen/>);
            default: return (
                <div className="d-flex h-100 justify-content-center align-items-center text-white">
                    <h1>Scherm niet ondersteund</h1>
                </div>
            );
        }
    }, [screenType]);

    const screenDisplayName = useMemo(() => {
        switch(screenType) {
            case "images": return "Foto's";
            case "mapPlanning": return "Kaart planning";
            case "mapCarTracker": return "CarTrackers";
            case "planning": return "Planning";
            case "weather": return "Weer";
            case "gridSense": return "GridSense";
            default: return "Onbekend";
        }
    }, [screenType]);

    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose }>
            <Modal.Header className="align-items-center">
                <Modal.Title>
                    <i className="fas fa-desktop ml-2 mr-3"/>
                    { screenDisplayName }
                </Modal.Title>
                <h4 className="m-0 text-danger monospace">
                    { timeoutString }
                </h4>
            </Modal.Header>
            <Modal.Body
                className="background p-0"
                style={{ backgroundImage: "url(" + regularBackground + ")" }}
            >
                <div className="background-overlay">
                    { screen }
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default React.memo(ScreenModal);
