import React from "react";

function ChartCard({ title, cardTitle, children, height = 500 }) {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <h5 className="card-title">{ cardTitle ? cardTitle : title }</h5>
                    </div>
                </div>
                <div className="w-100" style={{ height: `${height}px` }}>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default React.memo(ChartCard);
