import React, {
    useEffect,
    useMemo,
    useRef
} from "react";
import animateScrollTo from "animated-scroll-to";

function VerticalAutoScroll({
    children,
    controlled = false,
    showing = false,
    startInstantly = false,
    linear = false,
    duration = 10000,
    delay = 2500,
    className
}) {
    const scrollRef = useRef();

    const smoothScroll = useMemo(() => {
        return (object, height) => {
            animateScrollTo(height, {
                elementToScroll: object,
                minDuration: duration,
                easing: (t) => {
                    if(linear) {
                        return t;
                    }
                    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                }
            });
        }
    }, [duration, linear]);

    const triggerScroll = useMemo(() => {
        return (up) => {
            if(!scrollRef.current) {
                return;
            }
            if(up) {
                smoothScroll(scrollRef.current, 0);
            } else {
                smoothScroll(scrollRef.current, scrollRef.current.scrollHeight);
            }
        }
    }, [smoothScroll]);

    useEffect(() => {
        if(!controlled || !scrollRef.current) {
            return;
        }
        if(showing) {
            scrollRef.current.scrollTo(0, 0);
            setTimeout(() => {
                triggerScroll(false);
            }, 1000);
        }
    }, [controlled, showing, triggerScroll]);

    useEffect(() => {
        if(controlled) {
            return;
        }
        let top = true;
        if(startInstantly) {
            if(scrollRef.current) {
                triggerScroll(!top);
                top = !top
            }
        }
        const intervalId = setInterval(() => {
            if(scrollRef.current) {
                triggerScroll(!top);
                top = !top
            }
        }, duration + delay);
        return () => {
            clearInterval(intervalId);
        }
    }, [controlled, startInstantly, triggerScroll, scrollRef, delay, duration]);

    return (
        <div style={{ flex: "2", overflowY: "auto" }} ref={ scrollRef } className={ className }>
            { children }
        </div>
    )
}

export default React.memo(VerticalAutoScroll);
