import React from "react";
import moment from "moment";
import {
    Carousel
} from "react-bootstrap";

import ImageLeaderboardScreen from "./ImageLeaderboardScreen";
import Weather2Screen from "./Weather2Screen";
import MapScreen from "./MapScreen";
import ImagesScrollingScreen from "./ImagesScrollingScreen";
import getScrollingImagesScrollDuration from "../components/getScrollingImagesScrollDuration";
import CarTrackerScreen from "./CarTrackerScreen";
import AprilFoolsScreen from "./AprilFoolsScreen";
import GridSenseScreen from "./gridsense/GridSenseScreen";
import WKPouleScreen from "./WKPouleScreen";

function getRotatingScreens() {
    const now = moment().startOf("day");

    let screens = [];
    screens.push({
        component: ImagesScrollingScreen,
        duration: getScrollingImagesScrollDuration() + 2500,
    });
    screens.push({
        component: MapScreen,
        duration: 15 * 1000,
    });
    screens.push({
        component: CarTrackerScreen,
        duration: 15 * 1000,
    });
    screens.push({
        component: ImageLeaderboardScreen,
        duration: 5 * 1000,
    });
    if(now >= moment("2024-03-01") && now < moment("2024-07-17")) {
        screens.push({
            component: WKPouleScreen,
            duration: 15 * 1000,
        });
    }
    screens.push({
        component: Weather2Screen,
        duration: 10 * 1000,
    });
    screens.push({
        component: GridSenseScreen,
        duration: 15 * 1000,
    });
    if(now.format("MM-DD") === "04-01") {
        screens.push({
            component: AprilFoolsScreen,
            duration: 3 * 1000,
        });
    }
    return screens;
}

class RotatingScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        const screenConfig = getRotatingScreens();
        this.state = {
            currentScreen: 0,
            screens: screenConfig.map((screen) => screen.component),
            durations: screenConfig.map((screen) => screen.duration),
        }
        this.onSelect = this.onSelect.bind(this);
        this.setDuration = this.setDuration.bind(this);
    }

    onSelect(selectedIndex, e) {
        this.setState({ currentScreen: selectedIndex });
    }

    setDuration(screen, duration) {
        this.setState((prevState) => {
            return { durations: prevState.durations.map((prevDuration, index) => {
                if(index === screen) {
                    return duration;
                }
                return prevDuration;
            }) };
        });
    }

    render() {
        return (
            <Carousel
                activeIndex={ this.state.currentScreen }
                onSelect={ this.onSelect }
                controls={ false }
                pause={ false }
            >
                { this.state.screens.map((Component, index) => (
                    <Carousel.Item
                        interval={ this.state.durations[index] }
                        key={ index }
                        style={{
                            height: "100vh",
                        }}
                    >
                        <Component
                            inCarousel
                            setDuration={ (duration) => this.setDuration(index, duration) }
                            showing={ index === this.state.currentScreen }
                        />
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }
}

export default RotatingScreen;
