import {
    useMemo
} from "react";

function NumberFormatter({ number, decimals = undefined }) {
    const formatter = useMemo(() => {
        return new Intl.NumberFormat("nl-NL", {
            maximumFractionDigits: decimals
        });
    }, [decimals]);

    return formatter.format(number);
}

export default NumberFormatter;
