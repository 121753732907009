import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import moment from "moment-timezone";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    CSSTransition,
    SwitchTransition
} from "react-transition-group";

import getRequestHeaders from "../../utilities/getRequestHeaders";
import ChartCard from "../../components/charts/ChartCard";
import GridSenseEnergyPriceChart from "./GridSenseEnergyPriceChart";
import Loading from "../../components/Loading";
import useWindowSize from "../../hooks/WindowsSize";
import GridSenseEnergyHandlingChart from "./GridSenseEnergyHandlingChart";
import DashboardTotalEnergyFlowCard from "./energy-flow/DashboardTotalEnergyFlowCard";

import gridSenseLogo from "../../img/gridsense-logo-white.svg";

function GridSenseScreen({ showing }) {
    const windowSize = useWindowSize();
    const [chartData, setChartData] = useState(null);
    const [energyFlowData, setEnergyFlowData] = useState(null);
    const [error, setError] = useState(null);
    const [animate, setAnimate] = useState(false);
    const [showEnergyFlow, setShowEnergyFlow] = useState(false);

    const [now, setNow] = useState(moment());

    const [leftColumnState, setLeftColumnState] = useState(0);
    const [rightColumnState, setRightColumnState] = useState(0);
    const tomorrowRef = useRef(null);
    const liveRef = useRef(null);
    const nodeRef = rightColumnState === 0 ? tomorrowRef : liveRef;

    const refreshChartData = useCallback(async () => {
        setError(null);
        try {
            const response = await axios.get("/getGridSenseData", { headers: getRequestHeaders() });
            setChartData(response.data.data);
            setNow(moment());
        } catch(requestError) {
            setError("Er is een fout opgetreden bij het ophalen van de data.");
        }
    }, []);
    const refreshEnergyFlowData = useCallback(async () => {
        try {
            const response = await axios.get("/getGridSenseLiveData", { headers: getRequestHeaders() });
            if(!response.data.data) {
                setError("Geen data beschikbaar.")
                return;
            }
            setEnergyFlowData(response.data.data.totalEnergyFlow);
            setError(null);
            setAnimate(true);
            setTimeout(() => setAnimate(false), 3000);
        } catch(error) {
            console.error(error);
            setError("Er is een fout opgetreden. Probeer het later opnieuw.");
        }
    }, []);

    useEffect(() => {
        refreshChartData();
        const timer = setInterval(refreshChartData, 10 * 60 * 1000);
        return () => clearInterval(timer);
    }, [refreshChartData]);
    useEffect(() => {
        refreshEnergyFlowData();
        const timer = setInterval(refreshEnergyFlowData, 5000);
        return () => clearTimeout(timer);
    }, [refreshEnergyFlowData]);

    useEffect(() => {
        if(showing === false) {
            setLeftColumnState(0);
            setRightColumnState(0);
            return;
        }
        setLeftColumnState(0);
        setRightColumnState(chartData?.energyPricesTomorrow?.length > 0 ? 0 : 1);
        const timer = setInterval(() => {
            setLeftColumnState((prevState) => {
                return prevState === 0 ? 1 : 0;
            });
            if(chartData?.energyPricesTomorrow?.length > 0) {
                setRightColumnState((prevState) => {
                    return prevState === 0 ? 1 : 0;
                });
            } else {
                setRightColumnState(1);
            }
        }, 10 * 1000);
        return () => clearInterval(timer);
    }, [chartData, showing]);

    useEffect(() => {
        if(!energyFlowData || !showing) {
            setShowEnergyFlow(false);
            return;
        }
        if(rightColumnState !== 1) {
            return;
        }
        setTimeout(() => {
            setShowEnergyFlow(true);
        }, 750);
    }, [showing, energyFlowData, rightColumnState]);
    useEffect(() => {
        if(showing) {
            return;
        }
        setTimeout(() => {
            setShowEnergyFlow(false);
        }, 750);
    }, [showing]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    const chartHeight = windowSize.height - 450;
    return (
        <div className="gridsense-background vh-100 p-5">
            <div className="d-flex justify-content-center mb-5">
                <img
                    src={ gridSenseLogo }
                    alt="GridSense"
                    className="img-fluid"
                    style={{ maxHeight: "75px" }}
                />
            </div>
            <div className="row">
                <div className="col">
                    <ChartCard
                        title={ leftColumnState === 0 ? (
                            "Ruwe energieprijzen vandaag"
                        ) : (
                            <React.Fragment>
                                Energieprijzen vandaag{" "}
                                <small className="text-muted">incl. energiebelasting en inkoopkosten, excl. btw</small>
                            </React.Fragment>
                        ) }
                        height={ chartHeight }
                    >
                        { chartData ? (
                            <GridSenseEnergyPriceChart
                                chartData={ leftColumnState === 0 ? chartData.energyPricesToday : chartData.energyPricesTodayInclusive }
                                currentDateMoment={ now }
                            />
                        ) : (
                            <Loading/>
                        )}
                    </ChartCard>
                    <GridSenseEnergyHandlingChart
                        title="Sturing vandaag"
                        chartData={ chartData?.energyHandlingToday }
                    />
                </div>
                <div className="col">
                    <SwitchTransition mode="out-in">
                        <CSSTransition
                            key={ rightColumnState }
                            nodeRef={ nodeRef }
                            addEndListener={(done) => {
                                nodeRef.current.addEventListener("transitionend", done, false);
                            }}
                            classNames="fade"
                            unmountOnExit={ true }
                            mountOnEnter={ true }
                            onEntered={() => {
                                if(rightColumnState !== 1) {
                                    setShowEnergyFlow(false);
                                }
                            }}
                        >
                            <div ref={ nodeRef } className="h-100">
                                { rightColumnState === 0 ? (
                                    <div>
                                        <ChartCard
                                            title="Ruwe energieprijzen morgen"
                                            height={ chartHeight }
                                        >
                                            { chartData ? (
                                                <GridSenseEnergyPriceChart
                                                    chartData={ chartData.energyPricesTomorrow }
                                                    currentDateMoment={ now }
                                                />
                                            ) : (
                                                <Loading/>
                                            )}
                                        </ChartCard>
                                        <GridSenseEnergyHandlingChart
                                            title="Sturing morgen"
                                            chartData={ chartData?.energyHandlingTomorrow }
                                        />
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column h-100">
                                        <DashboardTotalEnergyFlowCard
                                            data={ showEnergyFlow ? energyFlowData : null }
                                            animate={ animate }
                                            className="flex-grow-1"
                                        />
                                        { chartData?.energyPricesTomorrow?.length === 0 && (
                                            <div className="card mb-3">
                                                <div className="card-body">
                                                    <h5 className="card-title">
                                                        Energieprijzen van morgen nog niet beschikbaar
                                                    </h5>
                                                    <p className="card-text">
                                                        De energieprijzen van morgen worden rond 13:30 gepubliceerd.
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </div>
        </div>
    )
}

export default React.memo(GridSenseScreen);
