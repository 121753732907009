import React from "react";

function WindDirectionIcon({ direction }) {
    return (
        <i className="fad fa-arrow-circle-down text-muted" style={{
            transform: "rotate(" + direction + "deg)"
        }}/>
    )
}

export default React.memo(WindDirectionIcon);
