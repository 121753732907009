function getToken() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("token");
}

function getRequestHeaders() {
    const token = getToken();
    return token ? { Token: token } : {};
}

export default getRequestHeaders;
