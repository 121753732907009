import React from "react";
import {
    Alert
} from "react-bootstrap";
import axios from "axios";
import Loading from "../components/Loading";
import NumberFormatter from "../components/NumberFormatter";

import background from "../img/wk.jpg";

function BackgroundContainer({ children }) {
    return (
        <div className="w-100 h-100" style={{
            background: `url(${background})`,
            backgroundSize: "cover",
            backgroundPosition: "center"
        }}>
            <div className="w-100 h-100 d-flex align-items-center justify-content-center" style={{
                backgroundColor: "rgba(0, 0, 0, 0.25)"
            }}>
                { children }
            </div>
        </div>
    )
}

function PouleEntry({ index, name, score, allZero }) {
    return (
        <div className="p-1">
            <div className="row align-items-center">
                <div className="col-1 text-center">
                    { index === 0 && score > 0 && (
                        <h4 className="m-0" style={{ color: "#FF5000" }}>
                            <small><i className="fas fa-award"/></small>
                        </h4>
                    )}
                </div>
                <div className="col-1 text-center" style={{ minWidth: "60px" }}>
                    <h4 className="m-0">
                        { allZero ? "-" : index + 1 }
                    </h4>
                </div>
                <div className="col">
                    <h5 className="m-0">
                        <div className="d-flex">
                            <div className="flex-grow-1 mr-5">
                                { name }
                            </div>
                            <div className="text-muted">
                                <NumberFormatter number={ score }/>
                            </div>
                        </div>
                    </h5>
                </div>
            </div>
        </div>
    )
}

function LeaderboardPodium({ entries }) {
    const first = entries[0];
    const second = entries[1];
    const third = entries[2];
    const width = "200px"
    return (
        <div className="d-flex flex-row">
            <div className="d-flex flex-column align-items-center flex-grow-1" style={{ paddingTop: "50px" }}>
                <h1 className="m-0 text-white" style={{ fontSize: "4rem" }}>
                    2
                </h1>
                <div className="card flex-grow-1" style={{ width }}>
                    <div className="card-body d-flex flex-column justify-content-end text-center">
                        <h4 className="mb-0">
                            { second?.name }
                        </h4>
                        <h3 className="m-0 text-muted">
                            <NumberFormatter number={ second?.score }/>
                        </h3>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center mx-3 flex-grow-1">
                <h1 className="m-0 text-white" style={{ fontSize: "4rem" }}>
                    1
                </h1>
                <div className="card flex-grow-1" style={{ width }}>
                    <div className="card-body d-flex flex-column justify-content-end text-center">
                        <h1 className="m-0" style={{ color: "#FF5000" }}>
                            <small><i className="fas fa-award"/></small>
                        </h1>
                        <h4 className="mb-0">
                            { first?.name }
                        </h4>
                        <h3 className="m-0 text-muted">
                            <NumberFormatter number={ first?.score }/>
                        </h3>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center flex-grow-1" style={{ paddingTop: "100px" }}>
                <h1 className="m-0 text-white" style={{ fontSize: "4rem" }}>
                    3
                </h1>
                <div className="card flex-grow-1" style={{ width }}>
                    <div className="card-body d-flex flex-column justify-content-end text-center">
                        <h4 className="mb-0">
                            { third?.name }
                        </h4>
                        <h3 className="m-0 text-muted">
                            <NumberFormatter number={ third?.score }/>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

class WKPouleScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            poule: null,
            allZero: null,
            error: null,
            hideUrl: false
        }
        this.getData = this.getData.bind(this);
        this.interval = null;
        this.loading = false;
    }

    componentDidMount() {
        this.getData();
        this.interval = setInterval(this.getData, 10 * 60 * 1000);
    }

    componentWillUnmount() {
        if(this.interval) {
            clearInterval(this.interval);
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getData() {
        if(this.loading) {
            return;
        }
        this.loading = true;
        this.setState({ error: null });
        axios.get("/getPouleData", { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    const {
                        title,
                        poule,
                        url
                    } = response.data;
                    const pouleSorted = poule.sort((entry1, entry2) => {
                        if(entry1.sorting !== undefined && entry2.sorting !== undefined) {
                            if(entry1.sorting > entry2.sorting) return 1;
                            if(entry1.sorting < entry2.sorting) return -1;
                        }
                        if(entry1?.score > entry2?.score) return -1;
                        if(entry1?.score < entry2?.score) return 1;
                        if(entry1?.name > entry2?.name) return 1;
                        if(entry1?.name < entry2?.name) return -1;
                        return 0;
                    })
                    const allZero = pouleSorted.findIndex((entry) => {
                        return entry?.score > 0;
                    }) === -1;
                    this.setState({
                        title,
                        poule: pouleSorted,
                        allZero,
                        url,
                        hideUrl: response.data.hideUrl
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout bij het ophalen van de data." });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    render() {
        const {
            title,
            poule,
            allZero,
            url,
            error,
            hideUrl
        } = this.state;
        if(error) {
            return (
                <BackgroundContainer>
                    <Alert variant="danger">{ error }</Alert>
                </BackgroundContainer>
            )
        }
        if(!poule || !title) {
            return (
                <BackgroundContainer>
                    <div>
                        <Loading white/>
                    </div>
                </BackgroundContainer>
            )
        }
        return (
            <BackgroundContainer>
                <div>
                    <h1 className="text-white text-center mb-3">
                        { title }
                    </h1>
                    <div className="d-flex align-items-start">
                        <div className="d-flex flex-column">
                            { !allZero && (
                                <div className="mb-3">
                                    <LeaderboardPodium entries={poule}/>
                                </div>
                            )}
                            <div className="card">
                                <div className="card-body">
                                    { poule.map((entry, index) => {
                                        if(!allZero && index <= 2) {
                                            return null;
                                        }
                                        return (
                                            <PouleEntry
                                                key={ index }
                                                index={ index }
                                                name={ entry?.name }
                                                score={ entry?.score ?? 0 }
                                                allZero={ allZero }
                                            />
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        { url && !hideUrl && (
                            <div className="card ml-3">
                                <div className="pt-3 pl-3 pr-3">
                                    <p className="card-text">
                                        Doe mee door te scannen
                                    </p>
                                </div>
                                <img src={ axios.defaults.baseURL + "/qrcode?content=" + url } alt="QR Code"/>
                            </div>
                        )}
                    </div>
                </div>
            </BackgroundContainer>
        )
    }
}

export default WKPouleScreen;
