import React from "react";
import Confetti from "react-confetti";

import useWindowSize from "../hooks/WindowsSize";

import wattco from "../img/wattco.jpg";

function AprilFoolsScreen() {
    const windowSize = useWindowSize();
    return (
        <div className="d-flex w-100 h-100 align-items-end" style={{ background: "url(" + wattco + ")", backgroundSize: "cover", backgroundPosition: "center" }}>
            <Confetti
                width={ windowSize.width }
                height={ windowSize.height }
            />
            <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: "50vh" }}>
                <h1 className="april-fools">
                    1 april
                </h1>
            </div>
        </div>
    )
}

export default React.memo(AprilFoolsScreen);
