import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../components/Loading";
import ImageGallary from "../components/ImageGallary";

class ImagesScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            images: null,
            error: null,
        }
    }

    componentDidMount() {
        this.refresh();
        this.refreshInterval = setInterval(() => {
            this.refresh();
        }, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    refresh() {
        this.getImages();
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getImages() {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};
        const filters = ["lead", "outage", "outageComment", "installation", "installationComment"].join(",");
        axios.post("/getImages", { amount: 6 * 4, offset: 0, filters }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ images: response.data.data });
                } else {
                    this.setState({ images: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: this.getErrorMessage(null) });
            });
    }

    render() {
        if(this.state.error) {
            return (
                <div className="container d-flex align-items-center">
                    <Alert variant="danger" className="w-100 text-center" style={{ fontSize: "1.5rem" }}>
                        { this.state.error }
                    </Alert>
                </div>
            )
        }
        if(!this.state.images) {
            return (
                <div className="container h-100 d-flex align-items-center">
                    <div className="w-100">
                        <Loading white/>
                    </div>
                </div>
            )
        }
        return (
            <div className="d-flex h-100 w-100 justify-content-center">
                <ImageGallary images={ this.state.images }/>
            </div>
        )
    }

}

export default ImagesScreen;
