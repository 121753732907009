import React from "react";
import Skeleton from "react-loading-skeleton";

import TagPill from "../TagPill";
import VerticalAutoScroll from "../VerticalAutoScroll";

function LoadingState() {
    return (
        <div className="d-flex w-100">
            <div style={{ width: "30%" }}>
                <Skeleton/>
            </div>
            <div className="ml-2 flex-grow-1">
                <Skeleton/>
            </div>
        </div>
    )
}

function AbsenceColumn({ absenceItems, columnFill = false }) {
    return (
        <div className="col d-flex h-100" style={{ flex: columnFill ? "1 0 21.5%" : undefined }}>
            <div className="card w-100">
                <div className="card-body h-100 d-flex flex-column">
                    { absenceItems === null ? (
                        <React.Fragment>
                            <LoadingState/>
                            <LoadingState/>
                        </React.Fragment>
                    ) : absenceItems.length === 0 ? (
                        <div className="d-flex h-100 align-items-center">
                            <p className="card-text">
                                Iedereen is aanwezig
                            </p>
                        </div>
                    ) : (
                        <VerticalAutoScroll
                            duration={ 3000 }
                            delay={ 2500 }
                        >
                            { absenceItems.map((absenceItem, index) => (
                                <div className="mb-0" key={ index } style={{ fontSize: "0.9rem" }}>
                                    <TagPill color={ absenceItem.type.color }>{ absenceItem.type.name }</TagPill>
                                    <b> { absenceItem.user.name }</b>
                                </div>
                            ))}
                        </VerticalAutoScroll>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(AbsenceColumn);
