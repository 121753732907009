import React from "react";
import axios from "axios";
import moment from "moment";
import Confetti from "react-confetti";

import useWindowSize from "../../hooks/WindowsSize";

function joinNames(array) {
    if(!array || array.length === 0) {
        return null;
    }
    if(array.length === 1) {
        return array[0];
    }
    let mutableArray = [...array];
    let last = mutableArray.pop();
    return mutableArray.join(", ") + " en " + last;
}

function isBirthdayToday(user) {
    return moment(user.birthday).format("MM-DD") === moment().format("MM-DD")
}

function BirthdayWidgetInternal({ birthdays }) {
    const windowSize = useWindowSize();
    const height = 80;
    const show = birthdays !== null && birthdays.length > 0;

    const namesPreviousDays = !show ? null : birthdays.filter((user) => !isBirthdayToday(user)).map((user) => user.name);
    const namesToday = !show ? null : birthdays.filter((user) => isBirthdayToday(user)).map((user) => user.name);

    if(!show) {
        return null;
    }
    return (
        <div className="card mt-2" style={{ overflowX: "hidden" }}>
            <Confetti
                width={ windowSize.width }
                height={ height }
            />
            <div className="card-body text-center d-flex flex-column justify-content-center" style={{ height: height + "px" }}>
                { namesToday.length > 0 && (
                    <h5 className="card-title mb-0">
                        { namesToday.length === 1 ? (
                            joinNames(namesToday) + " is jarig!"
                        ) : (
                            joinNames(namesToday) + " zijn jarig!"
                        )}
                    </h5>
                )}
                { namesPreviousDays.length > 0 && (
                    <h5 className="card-title mb-0">
                        { namesPreviousDays.length === 1 ? (
                            joinNames(namesPreviousDays) + " was jarig!"
                        ) : (
                            joinNames(namesPreviousDays) + " waren jarig!"
                        )}
                    </h5>
                )}
            </div>
        </div>
    )
}

class BirthdayWidget extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            birthdays: null,
            error: null
        }
        this.getBirthday = this.getBirthday.bind(this);
    }

    componentDidMount() {
        this.getBirthday();
        this.refreshInterval = setInterval(() => {
            this.getBirthday();
        }, 10 * 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getBirthday() {
        this.setState({ birthdays: null, error: null });
        axios.get("/getBirthday", { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    // this.setState({ birthdays: [{ name: "Test", birthday: "2022-08-10" }] });
                    this.setState({ birthdays: response.data.data });
                } else {
                    this.setState({ birthdays: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ birthdays: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    render() {
        return (
            <BirthdayWidgetInternal
                birthdays={ this.state.birthdays }
            />
        )
    }

}

export default BirthdayWidget;
