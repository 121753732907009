import React from "react";

function ChristmasLights({ count = 42, upsideDown }) {
    return (
        <div style={{ height: "40px" }}>
            <ul className="lightrope" style={{ transform: upsideDown ? "rotate(180deg)" : undefined }}>
                { [...Array(count)].map((value, index) => (
                    <li key={ index }/>
                ))}
            </ul>
        </div>
    );
}

export default React.memo(ChristmasLights);
