import React from "react";

import Loading from "../Loading";
import ReactMarkdownBreaks from "../ReactMarkdownBreaks";
import PlanningItemContent from "./PlanningItemContent";
import VerticalAutoScroll from "../VerticalAutoScroll";

function PlanningColumn({
    title,
    planningItems,
    planningComment,
    images,
    startInstantly = false,
    linear = false,
    duration = 10000,
    delay = 2500,
}) {
    return (
        <div className="col d-flex h-100">
            <div className="card w-100">
                <div className="card-body h-100 d-flex flex-column">
                    <h5 className="card-title">{ title }</h5>
                    { planningItems === null ? (
                        <Loading/>
                    ) : planningItems.length === 0 && !planningComment ? (
                        <div className="d-flex align-items-center w-100 h-100" style={{ flex: "2" }}>
                            <div className="text-center w-100">
                                <h1><i className="fas fa-calendar-alt"/></h1>
                                <h3>Niets gepland</h3>
                                <p>Er is op deze dag niets gepland.</p>
                            </div>
                        </div>
                    ) : (
                        <VerticalAutoScroll
                            className="planning-item-column"
                            startInstantly={ startInstantly }
                            linear={ linear }
                            duration={ duration }
                            delay={ delay }
                        >
                            { planningItems.map((planningItem) => (
                                <PlanningItemContent
                                    key={ planningItem.id }
                                    planningItem={ planningItem }
                                    images={ images }
                                />
                            ))}
                            { planningComment && planningComment.description.trim().length > 0 && (
                                <div className="planning-item">
                                    <b>Notities</b>
                                    <ReactMarkdownBreaks message={ planningComment.description }/>
                                </div>
                            )}
                        </VerticalAutoScroll>
                    )}
                </div>
            </div>
        </div>
    )
}

export default React.memo(PlanningColumn);
