import React from "react";
import axios from "axios";
import moment from "moment";
import io from "socket.io-client";

import ImageModal from "./ImageModal";
import PlanningItemsModal from "./PlanningItemsModal";
import ScreenModal from "./ScreenModal";

class ModalController extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            planningItems: null,
            screenType: null,
            error: null,

            showingModal: null,
            timeout: 0,
            timeoutString: "00:00"
        }
        this.handleModalClose = this.handleModalClose.bind(this);
        this.timeoutTimerFunction = this.timeoutTimerFunction.bind(this);
    }

    componentDidMount() {
        this.connect();
    }

    connect() {
        this.socket = io("https://intranet-monitoring-controller-pxk4q.ondigitalocean.app");
        this.socket.on("openImage", (imageId, screen) => {
            if(screen === this.getScreen()) {
                console.log("openImage: " + imageId);
                this.getImage(parseInt(imageId));
            }
        });
        this.socket.on("openPlanning", (date, screen) => {
            if(screen === this.getScreen()) {
                console.log("openPlanning: " + date);
                this.getPlanningItems(date);
            }
        });
        this.socket.on("openScreen", (screenType, screen) => {
            if(screen === this.getScreen()) {
                console.log("openScreen: " + screenType);
                this.openScreen(screenType);
            }
        });
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getUrlParams() {
        return new URLSearchParams(window.location.search);
    }

    getScreen() {
        return this.getUrlParams().get("screen");
    }

    getToken() {
        return this.getUrlParams().get("token");
    }

    getImage(imageId) {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};
        axios.post("/getImage", { imageId }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ image: response.data.image });
                    this.showModal("image");
                } else {
                    this.setState({ image: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ image: null, error: this.getErrorMessage(null) });
            });
    }

    getPlanningItems(date) {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};
        axios.post("/getPlanningItems", {
            startDate: date,
            endDate: date,
            withAvailability: 1,
        }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ planningItems: response.data.data });
                    this.showModal("planning");
                } else {
                    this.setState({ planningItems: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: this.getErrorMessage(null) });
            });
    }

    openScreen(screenType) {
        this.setState({ screenType });
        this.showModal("screen");
    }

    showModal(type) {
        this.setState({ showingModal: type });
        if(this.closeTimeout) {
            clearInterval(this.closeTimeout);
        }
        this.timeout = 120;
        this.timeoutTimerFunction();
        this.closeTimeout = setInterval(this.timeoutTimerFunction, 1000);
    }

    timeoutTimerFunction() {
        this.timeout -= 1;

        const string = moment(this.timeout * 1000).format("HH:ss");
        this.setState({ timeout: this.timeout, timeoutString: string });

        if(this.timeout <= 0) {
            this.handleModalClose();
            clearInterval(this.closeTimeout);
        }
    }

    handleModalClose() {
        this.setState({ showingModal: null });
    }

    render() {
        const {
            showingModal,
            image,
            planningItems,
            screenType,
            timeout,
            timeoutString
        } = this.state;
        return (
            <React.Fragment>
                <ImageModal
                    show={ showingModal === "image" }
                    handleClose={ this.handleModalClose }
                    image={ image }
                    hideControls
                />
                <PlanningItemsModal
                    show={ showingModal === "planning" }
                    handleClose={ this.handleModalClose }
                    planningItems={ planningItems }
                    timeout={ timeout }
                    timeoutString={ timeoutString }
                />
                <ScreenModal
                    show={ showingModal === "screen" }
                    handleClose={ this.handleModalClose }
                    screenType={ screenType }
                    timeoutString={ timeoutString }
                />
            </React.Fragment>
        )
    }
}

export default ModalController;
