import React from "react";

import OpenWeatherIcon from "./OpenWeatherIcon";
import NumberFormatter from "../NumberFormatter";
import WindDirectionIcon from "./WindDirectionIcon";

function CurrentWeatherCard({ current }) {
    return (
        <div className="card card-glass card-glass-darkblue">
            <div className="card-body">
                <h3 className="card-title mr-5">
                    { current.name }
                </h3>
                <div className="d-flex align-items-center">
                    <div className="mr-3">
                        <h1 className="m-0" style={{ fontSize: "3rem" }}>
                            <OpenWeatherIcon
                                icon={ current.weather[0].icon }
                            />
                        </h1>
                    </div>
                    <div>
                        <h2 className="mb-0">
                            <NumberFormatter number={ current.main.temp } decimals={ 1 }/> &#8451;
                        </h2>
                        <p className="mb-0 text-muted h5">
                            { current.weather[0].description }
                        </p>
                    </div>
                </div>
                <h3 className="m-0 mt-3 text-muted">
                    <span className="mr-3">
                        <WindDirectionIcon
                            direction={ current.wind.deg }
                        />
                    </span>
                    <NumberFormatter number={ current.wind.speed * 3.6 } decimals={ 0 }/> km/h
                </h3>
            </div>
        </div>
    )
}

export default React.memo(CurrentWeatherCard);
