import React from "react";
import moment from "moment";

import ChristmasLights from "../components/christmas/ChristmasLights";

function colorize(text) {
    const characters = text.split("");
    return characters.map((character, index) => {
        const color = Math.floor(Math.random() * 16777215).toString(16);
        return (
            <span style={{ color: "#" + color }} key={ index }>
                { character }
            </span>
        )
    });
}

class MerryChristmasScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.generateContent()
        }
        this.updateColors = this.updateColors.bind(this);
    }

    componentDidMount() {
        this.timer = setInterval(this.updateColors, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    updateColors() {
        this.setState({ content: this.generateContent() });
    }

    generateContent() {
        const year = moment().year() + 1;
        return (
            <h1>
                { colorize("Merry Christmas") }
                <br/>
                { colorize(`and a happy ${year}!`) }
            </h1>
        )
    }

    render() {
        return (
            <div className="d-flex flex-column h-100 w-100">
                <ChristmasLights/>
                <div className="flex-grow-1 text-white slogan merry-christmas w-100 d-flex align-items-center">
                    <div className="w-100">
                        { this.state.content }
                    </div>
                </div>
                <ChristmasLights upsideDown/>
            </div>
        )
    }
}

export default MerryChristmasScreen;
