import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";

import Loading from "../components/Loading";
import NumberFormatter from "../components/NumberFormatter";

function LeaderboardCard({ title, description, entries, projected }) {
    let total = 0;
    entries.forEach((entry) => {
        total += entry.numImages;
    });
    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title d-flex">
                    <div className="flex-grow-1">
                        { title }
                    </div>
                    <div>
                        { projected && total < projected && projected - total < total * 0.05 ? (
                            <b className="text-warning">
                                <NumberFormatter number={ total }/>
                                <span className="mx-2">/</span>
                                <NumberFormatter number={ projected }/>
                            </b>
                        ) : projected && projected > total ? (
                            <b className="text-danger">
                                <NumberFormatter number={ total }/>
                                <span className="mx-2">/</span>
                                <NumberFormatter number={ projected }/>
                            </b>
                        ) : (
                            <b className="text-muted">
                                <NumberFormatter number={ total }/>
                            </b>
                        )}
                    </div>
                </h4>
                <p className="text-muted">
                    { description }
                    { total < projected && (
                        <React.Fragment>
                            <br/>
                            <b className="text-danger">
                                Er zijn <NumberFormatter number={ projected - total }/> te weinig foto's gemaakt.
                            </b>
                        </React.Fragment>
                    )}
                </p>
                { entries.length === 0 ? (
                    <p className="mb-0">Nog geen foto's gemaakt.</p>
                ) : entries.map((entry, index) => (
                    <div className="p-1 d-flex align-items-center" key={ index }>
                        <div className="text-center" style={{ width: "20px" }}>
                            { index === 0 && (
                                <h4 className="m-0" style={{ color: "#FF5000" }}>
                                    <small><i className="fas fa-award"/></small>
                                </h4>
                            )}
                        </div>
                        <div className="text-center" style={{ width: "60px" }}>
                            <h4 className="m-0">
                                { index + 1 }
                            </h4>
                        </div>
                        <div className="flex-grow-1">
                            <h5 className="m-0">
                                { entry.name }
                            </h5>
                        </div>
                        <div className="text-muted">
                            <h5 className="m-0">
                                <NumberFormatter number={ entry.numImages }/>
                            </h5>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

function LeaderboardColumns({ leaderboardThisMonth, leaderboardLastMonth, projectedAmountThisMonth, projectedAmountLastMonth }) {
    const mechanicLeaderboardThisMonth = leaderboardThisMonth.filter((entry) => {
        return !entry.intranet;
    });
    const officeLeaderboardThisMonth = leaderboardThisMonth.filter((entry) => {
        return entry.intranet;
    });
    const mechanicLeaderboardLastMonth = leaderboardLastMonth.filter((entry) => {
        return !entry.intranet;
    });
    const officeLeaderboardLastMonth = leaderboardLastMonth.filter((entry) => {
        return entry.intranet;
    });
    return (
        <div className="container-fluid h-100">
            <div className="row h-100">
                <div className="col h-100">
                    <div className="ml-auto h-100 d-flex flex-column align-items-stretch justify-content-center" style={{ maxWidth: "500px" }}>
                        <h3 className="text-white mb-3">Vorige maand</h3>
                        <div className="mb-3">
                            <LeaderboardCard
                                title="Monteur foto's"
                                description="Aantal foto's gemaakt vorige maand"
                                entries={ mechanicLeaderboardLastMonth }
                                projected={ projectedAmountLastMonth }
                            />
                        </div>
                        <LeaderboardCard
                            title="Kantoormedewerkers foto's"
                            description="Aantal foto's gemaakt vorige maand"
                            entries={ officeLeaderboardLastMonth }
                        />
                    </div>
                </div>
                <div className="col h-100">
                    <div className="mr-auto h-100 d-flex flex-column align-items-stretch justify-content-center" style={{ maxWidth: "500px" }}>
                        <h3 className="text-white mb-3">Deze maand</h3>
                        <div className="mb-3">
                            <LeaderboardCard
                                title="Monteur foto's"
                                description="Aantal foto's gemaakt deze maand"
                                entries={ mechanicLeaderboardThisMonth }
                                projected={ projectedAmountThisMonth }
                            />
                        </div>
                        <LeaderboardCard
                            title="Kantoormedewerkers foto's"
                            description="Aantal foto's gemaakt deze maand"
                            entries={ officeLeaderboardThisMonth }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

class ImageLeaderboardScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            leaderboardThisMonth: null,
            leaderboardLastMonth: null,

            projectedAmountThisMonth: null,
            projectedAmountLastMonth: null,

            error: null,
        }
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh();
        this.refreshInterval = setInterval(this.refresh, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    refresh() {
        this.getLeaderboard(true);
        this.getLeaderboard(false);
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getLeaderboard(previousMonth) {
        this.setState({ error: null });
        const token = this.getToken();
        const headers = token ? { Token: token } : {};

        let startDate, endDate;
        if(!previousMonth) {
            startDate = moment().startOf("month").format("YYYY-MM-DD");
            endDate = moment().format("YYYY-MM-DD");
        } else {
            startDate = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD");
            endDate = moment().subtract(1, "month").endOf("month").format("YYYY-MM-DD");
        }

        axios.post("/getImageLeaderboard", { startDate, endDate }, { headers })
            .then((response) => {
                if(response.data.valid) {
                    if(!previousMonth) {
                        this.setState({
                            leaderboardThisMonth: response.data.data,
                            projectedAmountThisMonth: response.data.mechanicProjected
                        });
                    } else {
                        this.setState({
                            leaderboardLastMonth: response.data.data,
                            projectedAmountLastMonth: response.data.mechanicProjected
                        });
                    }
                } else {
                    this.setState({ error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: this.getErrorMessage(null) });
            });
    }

    render() {
        if(this.state.error) {
            return (
                <div className="container d-flex align-items-center">
                    <Alert variant="danger" className="w-100 text-center" style={{ fontSize: "1.5rem" }}>
                        { this.state.error }
                    </Alert>
                </div>
            )
        }
        if(!this.state.leaderboardThisMonth || !this.state.leaderboardLastMonth) {
            return (
                <div className="container d-flex align-items-center">
                    <Loading/>
                </div>
            )
        }
        return (
            <LeaderboardColumns
                leaderboardThisMonth={ this.state.leaderboardThisMonth }
                leaderboardLastMonth={ this.state.leaderboardLastMonth }
                projectedAmountThisMonth={ this.state.projectedAmountThisMonth }
                projectedAmountLastMonth={ this.state.projectedAmountLastMonth }
            />
        )
    }

}

export default ImageLeaderboardScreen;
