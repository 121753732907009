import React, {
    useMemo
} from "react";
import moment from "moment";

import NumberFormatter from "../NumberFormatter";
import OpenWeatherIcon from "./OpenWeatherIcon";
import WindDirectionIcon from "./WindDirectionIcon";

function HourlyWeatherCard({ weatherEntry, type }) {
    const className = useMemo(() => {
        let classNames = ["card", "card-glass", "card-glass-darkblue", "h-100"];
        if(type) {
            if(type === "start-spacer") {
                classNames.push("card-start");
            } else if(type === "end-spacer") {
                classNames.push("card-end");
            } else {
                classNames.push(`card-${type}`);
            }
        }
        return classNames.join(" ");
    }, [type]);

    const rain = useMemo(() => {
        if(!weatherEntry.rain) {
            return 0;
        }
        return weatherEntry.rain["3h"];
    }, [weatherEntry]);

    return (
        <React.Fragment>
            { (type === "start-spacer" || type === "single") && (
                <div className="col px-0" style={{ width: "10px" }}/>
            )}
            <div className="col px-0 text-center h-100">
                <div className={ className }>
                    <div className="card-body d-flex flex-column">
                        <h4 className="card-title mb-0">
                            { moment(weatherEntry["dt_txt"]).format("HH:mm") }
                        </h4>
                        <p className="card-text mt-0 text-muted">
                            { moment(weatherEntry["dt_txt"]).startOf("day").calendar().replace("om 00:00", "") }
                        </p>
                        <h1 className="card-title mb-0">
                            <OpenWeatherIcon
                                icon={ weatherEntry.weather[0].icon }
                            />
                        </h1>
                        <div className="flex-grow-1 mb-3 d-flex align-items-center justify-content-center">
                            <p className="card-text text-muted">
                                { weatherEntry.weather[0].description }
                            </p>
                        </div>
                        <h2 className="m-0">
                            <NumberFormatter number={ weatherEntry.main.temp } decimals={ 0 }/> &#8451;
                        </h2>
                        <p className="card-text text-muted">
                            <NumberFormatter number={ weatherEntry.main["feels_like"] } decimals={ 0 }/> &#8451;
                        </p>
                        <h3 className="mb-0">
                            <WindDirectionIcon
                                direction={ weatherEntry.wind.deg }
                            />
                        </h3>
                        <p className="mb-0">
                            <NumberFormatter number={ weatherEntry.wind.speed * 3.6 } decimals={ 0 }/> km/h
                        </p>
                        <small className="text-muted">
                            <NumberFormatter number={ weatherEntry.wind.gust * 3.6 } decimals={ 0 }/> km/h
                        </small>
                        <p className="card-text text-primary mt-2">
                            <NumberFormatter number={ rain } decimals={ 1 }/> mm
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default React.memo(HourlyWeatherCard);
