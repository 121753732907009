import React, {
    useMemo
} from "react";
import moment from "moment";
import {
    Modal
} from "react-bootstrap";

import PlanningItemContent from "../planning/PlanningItemContent";

function PlanningItemsModal({ show, handleClose, planningItems, timeoutString }) {
    const dateString = useMemo(() => {
        if(!planningItems || planningItems.length === 0) {
            return null;
        }
        const date = planningItems[0].date;
        return moment(date).calendar().replace("om 00:00", "");
    }, [planningItems]);
    return (
        <Modal size="fullscreen" show={ show } onHide={ handleClose }>
            <Modal.Header className="align-items-center">
                <Modal.Title>
                    <i className="fas fa-calendar-alt ml-2 mr-3"/>
                    { dateString ? "Planning voor " + dateString : "Planning" }
                </Modal.Title>
                <h4 className="m-0 text-danger monospace">
                    { timeoutString }
                </h4>
            </Modal.Header>
            <Modal.Body className="p-0">
                <div className="container-fluid my-3">
                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5">
                        { planningItems && (
                            <React.Fragment>
                                { planningItems.map((planningItem) => (
                                    <div className="col mb-2">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <PlanningItemContent
                                                    key={ planningItem.id }
                                                    planningItem={ planningItem }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default React.memo(PlanningItemsModal);
