import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import {
    Annotation,
    ColorScheme,
    MapType
} from "@zandor300/react-apple-mapkitjs";

import AppleMapsMap from "../components/apple-maps/AppleMapsMap";
import Loading from "../components/Loading";
import getCenterCoordinate from "../utilities/getCenterCoordinate";

class MapScreen extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            planningItems: null,
            error: null,
            center: getCenterCoordinate([])
        }
        this.getPlanningItems = this.getPlanningItems.bind(this);
    }

    componentDidMount() {
        this.getPlanningItems();
        this.refreshInterval = setInterval(this.getPlanningItems, 60 * 1000);
    }

    componentWillUnmount() {
        clearInterval(this.refreshInterval);
    }

    getToken() {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("token");
    }

    getRequestHeaders() {
        const token = this.getToken();
        return token ? { Token: token } : {};
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getPlanningItems() {
        this.setState({ error: null });
        const date = moment().format("YYYY-MM-DD");
        axios.post("/getPlanningItems", { startDate: date, endDate: date }, { headers: this.getRequestHeaders() })
            .then((response) => {
                if(response.data.valid) {
                    const planningItems = response.data.data.filter((planningItem) => {
                        return planningItem.latitude && planningItem.longitude;
                    });
                    this.setState({
                        planningItems,
                        center: getCenterCoordinate(planningItems)
                    });
                    if(this.props.setDuration) {
                        this.props.setDuration(planningItems.length === 0 ? 2 * 1000 : 15 * 1000);
                    }
                } else {
                    this.setState({ planningItems: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: this.getErrorMessage(null) });
            });
    }

    render() {
        const planningItems = this.state.planningItems;
        if(this.state.error) {
            return (
                <Alert variant="danger">{ this.state.error }</Alert>
            )
        }
        if(!planningItems) {
            return (
                <Loading white/>
            )
        }
        const {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        } = this.state.center;
        return (
            <React.Fragment>
                { planningItems.length === 0 && (
                    <div className="text-white d-flex justify-content-center align-items-center" style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1
                    }}>
                        <h1>Geen planning items met locatie.</h1>
                    </div>
                )}
                <AppleMapsMap
                    autoAdjust
                    latitude={ centerLatitude ? centerLatitude : 51.994459 }
                    longitude={ centerLongitude ? centerLongitude : 4.180937 }
                    spanLat={ latitudeSpan ? latitudeSpan : 0.07 }
                    spanLong={ longitudeSpan ? longitudeSpan : 0.05 }
                    height="100%"
                    width="100%"
                    initialMapType={ MapType.Hybrid }
                    colorScheme={ ColorScheme.Dark }
                >
                    { planningItems.map((planningItem) => {
                        return (
                            <Annotation
                                key={ planningItem.id }
                                id={ planningItem.id }
                                latitude={ planningItem.latitude }
                                longitude={ planningItem.longitude }
                                color={ planningItem.type.color }
                                title={ planningItem.name }
                            />
                        )
                    })}
                </AppleMapsMap>
            </React.Fragment>
        )
    }
}

export default MapScreen;
