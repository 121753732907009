import React from "react";
import {
    Link
} from "react-router-dom";
import {
    Button,
    Modal
} from "react-bootstrap";

import useWindowSize from "../../hooks/WindowsSize";

function ImageModal({ show, handleClose, image, hideControls }) {
    const size = useWindowSize();
    const onMouseClickBackground = event => {
        if(event.target.nodeName !== "IMG") {
            handleClose();
        }
    };
    return (
        <Modal size="image" show={ show } onHide={ handleClose }>
            { !hideControls && (
                <Button className="modal-image-close" variant="link" size="lg" onClick={ handleClose }>
                    <i className="fas fa-times"/>
                </Button>
            )}
            <Modal.Body className="text-center" onClick={ onMouseClickBackground }>
                { image !== null && (
                    <img src={ image.url } alt="Selected"
                         style={{ maxWidth: "100%", maxHeight: size.height }} />
                )}
            </Modal.Body>
            { !hideControls && (
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose }>
                        Sluiten
                    </Button>
                    { image !== null && (
                        <React.Fragment>
                            <a href={ image.url } className="btn btn-secondary" target="_blank" rel="noreferrer noopener">
                                Origineel openen
                            </a>
                            { image.parent !== null && (
                                image.parentType === "lead" ? (
                                    <Link to={ "/lead/" + image.parent.id } className="btn btn-primary">
                                        Lead openen
                                    </Link>
                                ) : image.parentType === "leadComment" ? (
                                    <Link to={ "/lead/" + image.parent.leadId } className="btn btn-primary">
                                        Lead openen
                                    </Link>
                                ) : image.parentType === "outage" ? (
                                    <Link to={ "/outage/" + image.parent.id } className="btn btn-primary">
                                        Storing openen
                                    </Link>
                                ) : image.parentType === "outageComment" && (
                                    <Link to={ "/outage/" + image.parent.outageId } className="btn btn-primary">
                                        Storing openen
                                    </Link>
                                )
                            )}
                        </React.Fragment>
                    )}
                </Modal.Footer>
            )}
        </Modal>
    )
}

export default React.memo(ImageModal);
